import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.png";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as LinkedInIcon } from "../../images/linkedin-icon.svg";
import { ReactComponent as MailIcon } from "../../images/mail.svg";
import { ReactComponent as InstaIcon } from "../../images/instagram.svg";
const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/4`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;
const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;
const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (<>
    <Container>
      <FiveColumns>
        <Column style={{ margin: 16, paddingInline: 16 }}>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>ZFA Labs Pvt. Ltd.</LogoText>
          </LogoContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.linkedin.com/company/zfa-labs-pvt-ltd">
              <LinkedInIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com/ZFALabs">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://instagram.com/ZFALabs">
              <InstaIcon />
            </SocialLink>
            <SocialLink href="mailto:info@zfatech.in">
              <MailIcon />
            </SocialLink>
          </SocialLinksContainer>

        </Column>

        <Column style={{ margin: 16, paddingInline: 16 }}>
          <ColumnHeading>Corporate Office</ColumnHeading>
          <CompanyDescription>
            NDC, IT Park Road, Metro Vihar, Shastri Park, Shahdara, New Delhi,India, 110053
          </CompanyDescription>

        </Column>
        <Column style={{ margin: 16, paddingInline: 16 }}>
          <ColumnHeading>Contact</ColumnHeading>
          <CompanyDescription>
            info@zfatech.in
            <br />
            +91 9527 291 069
          </CompanyDescription>

        </Column>
      </FiveColumns>


    </Container>
    <CopyrightText>
      &copy; Copyright 2024, ZFA Labs Private Limited.
    </CopyrightText></>
  );
};
